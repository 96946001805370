import { handleResponse } from "./../_helpers";
import { BehaviorSubject } from "rxjs";
const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
const currentAdminSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentAdmin"))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  }
};

function login(Code, Access, Password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username: Code, password: Password })
  };

  return fetch(
    `https://vtronn.com/tusapi/public/api/auth/login`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (response.user.role == "Client" || response.user.role == "client") {
        localStorage.setItem("Token", response.token);
        fetchEm(response.token);
        currentUserSubject.next(response.user);
      } else if (response.user.role == "Admin") {
        localStorage.setItem("currentAdmin", JSON.stringify(response.user));
        currentAdminSubject.next(response.user);
        localStorage.setItem("Token", response.token);
      }
      //console.log(response);
      return response.user;
    });
}

const fetchEm = async token => {
  const fetchEm = await fetch(
    `https://vtronn.com/tusapi/public/api/customer_info`,
    {
      crossDomain: true,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
  var data = await fetchEm.json();

  localStorage.setItem("currentUser", JSON.stringify(data.data));
};

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}
