import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import SimpleForm from './../views/chatbot/SimpleForm';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,  CBadge,CButton
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilMenu, cilBell} from '@coreui/icons'
import {authenticationService} from './../_services/index'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import logo from 'src/assets/brand/espace-client-phone.png'
import part11 from 'src/assets/images/chatbot.png'
import part12 from 'src/assets/images/fermer.png'


const AppHeader = () => {
  var currentUser= authenticationService.currentUserValue;
  var code= currentUser.code;
  const [total, setTotal] = useState([])
  

 function handleLocationClick() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    console.log("Geolocation not supported");
  }
}

function success(position) {
  const latitude = position.coords.latitude;
  const longitude = position.coords.longitude;
 
  
  fetch(`https://vtronn.com/tusapi/public/api/errors`,{
    crossDomain: true,
    method: "POST",
            body: JSON.stringify({
             latitude: latitude,
             longitude:longitude
            }),
    headers: {
      Authorization: "Bearer " + localStorage.getItem(`Token`),
      "Content-Type": "application/json"
    }
  })
      .then(response => response.json())
      .then(data => {
       
        console.log(data);
      })
      .catch(error => console.log(error));
}

function error() {
  console.log("Unable to retrieve your location");
}
 
  const fetchEm = async () => {
    const fetchEm = await fetch(
        `https://vtronn.com/tusapi/public/api/cheques`,
        {
            crossDomain: true,
            headers: {
                Authorization:
                    "Bearer " + localStorage.getItem(`Token`),
             
            },method:'POST'
        }
    );
    var data = await fetchEm.json();
 
    setTotal(data.data.length);
    
};
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  let [showChat, setShowChat] = useState(false);

  const startChat = () => { setShowChat(true); }
  const hideChat = () => { setShowChat(false); }
  useEffect(() => {
    fetchEm()
    handleLocationClick()
  }, [])
  

  return (
    <CHeader position="sticky" className="appheader">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className=" d-md-none" to="/">
          <img src={logo} height={40} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/" component={NavLink} activeClassName="active">
              Dashboard
            </CNavLink>
          </CNavItem>
          
         
      
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
        
          </CNavItem>
          <CNavItem>
            <CNavLink href="/cheque">
              

          <CButton color='white'  className="position-relative">
          <CIcon icon={cilBell} size="lg" />
  <CBadge
    className="border border-light p-2"
    color="danger"
    position="top-end"
    shape="rounded-circle"
  >
     {total} 
    
  </CBadge>
</CButton>
            </CNavLink>
          
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
        <div className = "bot">
        <div style ={{display: showChat ? "" : "none"}}>
        <div className="chatdiv">
          <SimpleForm></SimpleForm>
          </div>
        </div>      
       
        {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
        <div className="open-button"> 
          {!showChat 
            ?      <img src={part11} height={65}   alt="part11"  onClick={() => startChat()} />
            
            :    <img src={part12}  height={65}   alt="part12"  onClick={() => hideChat()} />}
        </div>
        
        </div>
      </CContainer>
    </CHeader>
    
  )
}

export default AppHeader
