import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { ModalHeader, Modal } from "reactstrap";
import { AppHeader, AppSidebar } from "../../../components/index";

import {
  Button,
  ModalBody,
  ModalFooter,
  Input,
  Label as BootstrapLabel
} from "reactstrap";

import styled from "styled-components";
import "./formation.css";

const Formation = () => {
  const [data, setData] = useState([]);

  const user = JSON.parse(localStorage.getItem("currentUser"));

 
  const [type_formation, setType_formation] = useState("");
  const [foi, setFoi] = useState("");
  const [date, setDate] = useState("");
  const [temps_debut, setTemps_debut] = useState("");
  const [temps_fin, setTemps_fin] = useState("");
  const [note, setNote] = useState("");
  const [formationTypes, setFormationTypes] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [selectedUpdate, setSelectedUpdate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [rating, setRating] = useState(0);
  const [evaluation, setEvaluation] = useState("");
  const [visibleupdate, setVisibleupdate] = useState(false);
  const [visibleevaluer, setVisibleevaluer] = useState(false);


  const renderStars = () => {
    const stars = [];
    const maxRating = 5; // Le nombre maximum d'étoiles

    for (let i = 1; i <= maxRating; i++) {
      stars.push(
        <span
          key={i}
          className={`star ${i <= rating ? "filled" : ""}`}
          onClick={() => handleStarClick(i)}
        >
          ★
        </span>
      );
    }

    return stars;
  };
  const renderStarsList = ratinglist => {
    const stars = [];
    const maxRating = 5; // Le nombre maximum d'étoiles

    for (let i = 1; i <= maxRating; i++) {
      stars.push(
        <span
          key={i}
          className={`star ${i <= ratinglist ? "filled" : ""}`}
          onClick={() => handleStarClick(i)}
        >
          ★
        </span>
      );
    }

    return stars;
  };

  const handleStarClick = selectedRating => {
    setRating(selectedRating);
  };

  useEffect(() => {
    fetchEm();
    handleType();
  }, []);
  // fetch all formations
  const fetchEm = async () => {
    try {
      const response = await fetch(
        `https://vtronn.com/tusapi/public/api/formation/index/client/${user.No}`,
        {
          crossDomain: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Token")
          }
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch records");
      }

      const { records } = await response.json();
      console.log(records);
      setData(records);
    } catch (error) {
      console.error(error);
    }
  };
  // supprimer une formation
  const handleDelete = async id => {
    try {
      const response = await fetch(
        `https://vtronn.com/tusapi/public/api/formation/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Token")
          }
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete record");
      }

      setData(prevData => prevData.filter(record => record.id !== id));
    } catch (error) {
      console.error(error);
    }
  };
  // voir une formation
  const handleDetailClick = async id => {
    try {
      const response = await fetch(
        `https://vtronn.com/tusapi/public/api/formation/view/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(`Token`),
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch formation details");
      }

      const formationData = await response.json();
      setSelectedDetail(formationData);
    } catch (error) {
      console.error(error);
    }
  };
  // mise a jour de formation
  const handleUpdateClick = async (id, actiontype) => {
    try {
      const response = await fetch(
        `https://vtronn.com/tusapi/public/api/formation/view/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(`Token`),
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch formation modifier");
      }

      const formationData = await response.json();

      setSelectedUpdate(formationData);
      if (actiontype == "update") {
        setVisibleupdate(true);
        setVisibleevaluer(false);
      } else if (actiontype == "evaluer") {
        setVisibleevaluer(true);
        setVisibleupdate(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitupdate = async id => {
    try {
      const response = await fetch(
        `https://vtronn.com/tusapi/public/api/formation/edit/${id}`,
        {
          method: "POST",
          body: JSON.stringify({
            foi: selectedUpdate.foi,
            type_formation: selectedUpdate.type_formation,

            date: selectedUpdate.date,
            temps_debut: selectedUpdate.temps_debut,
            temps_fin: selectedUpdate.temps_fin,

            note: selectedUpdate.note
          }),
          headers: {
            Authorization: "Bearer " + localStorage.getItem(`Token`),
            "Content-Type": "application/json"
          }
        }
      );

      if (response.ok) {
        fetchEm();
        setSelectedUpdate(null);
        setIsModalOpen(false);
      } else if (!response.ok) {
        throw new Error("Failed to fetch formation modifier");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Evaluer une formation
  const handleSave = async id => {
    if (!evaluation && !rating) {
      alert("Veuillez remplir tous les champs.");
      return;
    } else {
      try {
        const response = await fetch(
          `https://vtronn.com/tusapi/public/api/formation/evaluer/${id}`,
          {
            method: "POST",
            body: JSON.stringify({
              evaluation: evaluation,
              stars: rating
            }),
            headers: {
              Authorization: "Bearer " + localStorage.getItem(`Token`),
              "Content-Type": "application/json"
            }
          }
        );
        if (response.ok) {
          fetchEm();
          setIsModalOpen(false);
          setSelectedUpdate(null);
          setRating(0);
          setEvaluation("");
          console.log("");
        } else if (!response.ok) {
          throw new Error("Failed to fetch formation modifier");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // ajouter une formation
  const handleAdd = async e => {
    e.preventDefault();
    if (
      !type_formation ||
      !foi ||
      !date ||
      !temps_debut ||
      !temps_fin ||
      !note
    ) {
      alert("Veuillez remplir tous les champs.");
      return;
    } else {
      try {
        const response = await fetch(
          `https://vtronn.com/tusapi/public/api/formation/add`,
          {
            method: "POST",
            body: JSON.stringify({
              foi: foi,
              type_formation: type_formation,
              client: JSON.parse(localStorage.getItem("currentUser")).No,
              nom_client: JSON.parse(localStorage.getItem("currentUser")).Name,
              commercial: JSON.parse(localStorage.getItem("currentUser"))
                .Salesperson_Code,
              date: date,
              temps_debut: temps_debut,
              temps_fin: temps_fin,
              approbation: "nouvelle",
              note: note
            }),
            headers: {
              Authorization: "Bearer " + localStorage.getItem(`Token`),
              "Content-Type": "application/json"
            }
          }
        );
        if (response.ok) {
          fetchEm();
          setIsModalOpen(false);
          setType_formation("");
          setFoi("");
          setDate("");
          setTemps_debut("");
          setTemps_fin("");
          setNote("");
        } else if (!response.ok) {
          throw new Error("Failed to fetch formation modifier");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleType = async id => {
    try {
      const response = await fetch(
        "https://vtronn.com/tusapi/public/api/type_formation",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(`Token`),
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch formation modifier");
      }

      const typeformation = await response.json();
      console.log(typeformation);
      setFormationTypes(typeformation.records);
    
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    { field: "foi", headerName: "Foi", width: 150 },
    { field: "type_formation", headerName: "Type", width: 100 },
    { field: "commercial", headerName: "Commercial", width: 130 },
    { field: "formateur", headerName: "Formateur", width: 150 },
    { field: "date", headerName: " Date de formation", width: 150 },
    { field: "temps_debut", headerName: "Debut de formation", width: 150 },
    { field: "temps_fin", headerName: "Fin de formation", width: 150 },
    { field: "approbation", headerName: "Status", width: 150 },
    {
      field: "stars",
      headerName: "Evaluation",
      width: 150,
      renderCell: data => renderStarsList(data.row.stars)
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: data => (
        <div>
          {data.row.approbation === "accept" ||
          data.row.approbation === "refuse" ? (
            <>
              <button
                onClick={() => handleDetailClick(data.id)}
                className="btn btn-primary m-1"
              >
                Détail
              </button>
              {data.row.stars == 0 ? (
                <>
                  <button
                    onClick={() => handleUpdateClick(data.id, "evaluer")}
                    className="btn btn-secondary m-1"
                  >
                    Evaluez
                  </button>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <button
                onClick={() => handleDetailClick(data.id)}
                className="btn btn-primary m-1"
              >
                Détail
              </button>
              <button
                onClick={() => handleUpdateClick(data.id, "update")}
                className="btn btn-warning m-1"
              >
                Modifier
              </button>
              <button
                className="btn btn-danger m-1"
                onClick={() => handleDelete(data.id)}
              >
                Supprimer
              </button>
            </>
          )}
        </div>
      )
    }
  ];

  return (
    <div>
      {" "}
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader />

        <button
          className="btn btn-success"
          onClick={toggleModal}
          style={{ margin: "auto" }}
        >
          Demander une formation
        </button>
        <form className="form-wrapper"></form>

        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader style={{ margin: "auto" }}>
            Demander formation
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardItem>
                <BootstrapLabel>Formation ou intervention:</BootstrapLabel>
                <Input
                  type="select"
                  name="formation"
                  id="formation"
                  onChange={e => setFoi(e.target.value)}
                >
                  <option value="">Sélectionner</option>
                  <option value="formation">Formation</option>
                  <option value="intervention">Intervention</option>
                </Input>
              </CardItem>
              <br />
              <CardItem>
                <BootstrapLabel>Type de formation:</BootstrapLabel>
                <Input
                  type="select"
                  name="typeFormation"
                  id="typeFormation"
                  onChange={e => setType_formation(e.target.value)}
                >
                  <option value="">Sélectionner un type de formation</option>
                  {formationTypes.map(formationType => (
                    <option key={formationType.id} value={formationType.name}>
                      {formationType.name}
                    </option>
                  ))}
                </Input>
              </CardItem>{" "}
              <br />
              <CardItem>
                <BootstrapLabel>Date de formation:</BootstrapLabel>
                <Input
                  type="date"
                  name="formation"
                  id="formation"
                  onChange={e => setDate(e.target.value)}
                />
              </CardItem>
              <br />
              <CardItem>
                {" "}
                <BootstrapLabel>Heure de début:</BootstrapLabel>
                <Input
                  type="time"
                  onChange={e => setTemps_debut(e.target.value)}
                />
              </CardItem>
              <CardItem>
                <BootstrapLabel>Heure de fin:</BootstrapLabel>
                <Input
                  type="time"
                  onChange={e => setTemps_fin(e.target.value)}
                />
              </CardItem>
              <CardItem>
                <BootstrapLabel>Note:</BootstrapLabel>
                <Input
                  type="textarea"
                  onChange={e => setNote(e.target.value)}
                />
              </CardItem>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleAdd}
              style={{ margin: "auto" }}
            >
              Envoyer
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={selectedUpdate !== null && visibleevaluer}
          toggle={() => setSelectedUpdate(null)}
        >
          <ModalHeader style={{ margin: "auto" }}>
            Evaluez la formation
          </ModalHeader>
          <ModalBody>
            
                <div className="row">
                  <div className="col-6"><h6>Evaluez :</h6></div>
                  <div className="col-6">{renderStars()}</div>
                </div>
                <div className="row"><div className="col-6"><h6>Votre avis :</h6></div></div>
                <div className="row"><div className="col-12"><Input
                  type="textarea"
                  value={evaluation}
                  onChange={e => setEvaluation(e.target.value)}
                  placeholder="Saisissez votre avi ici"
                  rows="5"
                /></div></div>
               
                
              
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (selectedUpdate !== null) {
                  handleSave(selectedUpdate.id);
                }
              }}
            >
              Enregistrer
            </Button>{" "}
            <Button color="secondary" onClick={() => setSelectedUpdate(null)}>
              Annuler
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={selectedDetail !== null}
          toggle={() => setSelectedDetail(null)}
        >
          <ModalHeader style={{ margin: "auto" }}>
            {" "}
            Details Formation{" "}
          </ModalHeader>
          <ModalBody>
            {selectedDetail && (
              <Card>
                <CardItem>
                  <Label>Type:</Label>
                  <Value>{selectedDetail.type_formation}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Client:</Label>
                  <Value>{selectedDetail.client}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Date:</Label>
                  <Value>{selectedDetail.date}</Value>
                </CardItem>
              </Card>
            )}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={selectedUpdate !== null && visibleupdate}
          toggle={() => setSelectedUpdate(null)}
        >
          <ModalHeader style={{ margin: "auto" }}>
            Modifier Formation
          </ModalHeader>
          <ModalBody>
            {selectedUpdate && (
              <Card>
                <CardItem>
                  <CardItem>
                    <BootstrapLabel>Formation ou intervention:</BootstrapLabel>
                    <Input
                      type="select"
                      value={selectedUpdate.foi}
                      onChange={e =>
                        setSelectedUpdate({
                          ...selectedUpdate,
                          foi: e.target.value
                        })
                      }
                    >
                      <option value="">Sélectionner</option>
                      <option value="formation">Formation</option>
                      <option value="intervention">Intervention</option>
                    </Input>
                  </CardItem>{" "}
                  <br />
                  <BootstrapLabel>Type de formation:</BootstrapLabel>
                  <Input
                    type="select"
                    name="typeFormation"
                    id="typeFormation"
                    value={selectedUpdate.type_formation}
                    onChange={e =>
                      setSelectedUpdate({
                        ...selectedUpdate,
                        type_formation: e.target.value
                      })
                    }
                  >
                    <option value="">Sélectionner un type de formation</option>
                    {formationTypes.map(formationType => (
                      <option key={formationType.id} value={formationType.name}>
                        {formationType.name}
                      </option>
                    ))}
                  </Input>
                </CardItem>{" "}
                <br />
                <br />
                <CardItem>
                  <BootstrapLabel>Date de formation:</BootstrapLabel>
                  <Input
                    type="date"
                    value={selectedUpdate.date}
                    onChange={e =>
                      setSelectedUpdate({
                        ...selectedUpdate,
                        date: e.target.value
                      })
                    }
                  />
                </CardItem>{" "}
                <br />
                <CardItem>
                  {" "}
                  <BootstrapLabel>Heure de début:</BootstrapLabel>
                  <Input
                    type="time"
                    value={selectedUpdate.temps_debut}
                    onChange={e =>
                      setSelectedUpdate({
                        ...selectedUpdate,
                        temps_debut: e.target.value
                      })
                    }
                  />
                </CardItem>
                <CardItem>
                  <BootstrapLabel>Heure de fin:</BootstrapLabel>
                  <Input
                    type="time"
                    value={selectedUpdate.temps_fin}
                    onChange={e =>
                      setSelectedUpdate({
                        ...selectedUpdate,
                        temps_fin: e.target.value
                      })
                    }
                  />
                </CardItem>
                <CardItem>
                  <BootstrapLabel>Note:</BootstrapLabel>
                  <Input
                    type="textarea"
                    value={selectedUpdate.note}
                    onChange={e =>
                      setSelectedUpdate({
                        ...selectedUpdate,
                        note: e.target.value
                      })
                    }
                  />
                </CardItem>
              </Card>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (selectedUpdate !== null) {
                  submitupdate(selectedUpdate.id);
                }
              }}
              style={{ margin: "auto" }}
            >
              Envoyer
            </Button>
          </ModalFooter>
        </Modal>
        {/* <Link to="/formation/add">Demander Formation</Link>
         */}

        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "date", sort: "desc" }]
            }
          }}
          style={{ margin: "20px" }}
          rows={data}
          columns={columns}
          pageSize={10}
          components={{
            Toolbar: GridToolbar
          }}
        />
      </div>
    </div>
  );
};
<button
  className="btn -sm btn-primary m-1"
  onClick={() => {
    window.location.href = "/formation/add";
  }}
>
  Demander Formation
</button>;

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Value = styled.span`
  margin-bottom: 10px;
`;

export default Formation;
