import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Login.scss";
import { cilLowVision } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import tus from "src/assets/images/tus.png";
import { CInputGroup, CInputGroupText } from "@coreui/react";
import { authenticationService } from "./../../../_services";

class Login extends React.Component {
  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };


  state = {
    location: null,
   
    errorMessage: null,
    isPasswordShown: false
  };

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  onSubmitSuccessRedirect = from => {
    // Redirect the user to the specified page
    this.props.history.push(from);

    // Refresh the page
    window.location.reload();
  };
  componentDidMount() {
    this.getLocation();
   
  }

  render() {
    const { errorMessage, isPasswordShown } = this.state;

    return (
      <div>
        <Formik
          initialValues={{
            Code: "",
            Access: "OK",
            Password: ""
          }}
          validationSchema={Yup.object().shape({
            Code: Yup.string().required("Code obligatoire"),
            Password: Yup.string().required("Mot de passe obligatoire"),
            Access: Yup.string().required("Access is required")
          })}
          onSubmit={(
            { Code, Access, Password },
            { setStatus, setSubmitting }
          ) => {
            setStatus();
            authenticationService.login(Code, Access, Password).then(
              user => {
                if (
                  user.role === "client" ||
                  (user.role === "Client" && user.status === "Active")
                ) {
                  console.log(user);
                  const { from } = this.props.location.state || {
                    from: { pathname: "/" }
                  };
                  this.props.history.push(from);
                } else if (user.status === "Inactive") {
                  this.setState({
                    errorMessage:
                      "Le compte est inactif. Veuillez contacter l'administrateur."
                  });
                } else if (user.role === "Admin") {
                  this.props.history.push("/home");
                }
              },
              error => {
                setSubmitting(false);
                setStatus("Nom d'utilisateur ou mot de passe incorrect.");
              }
            );
          }}
          render={({ errors, status, touched, isSubmitting }) => (
            <div className="loginback d-flex justify-content-center align-item-center">
              <div className="background container-fluid">
                <Form className="container-fluid">
                  <div>
                    <div className="row d-flex justify-content-center mt-2">
                      <img src={tus} style={{ width: "130px" }} alt="tus" />
                    </div>
                    <div className="row mt-4">
                      <div className="form-group" style={{ color: "white" }}>
                        <label htmlFor="Code">Code </label>
                        <Field
                          name="Code"
                          type="text"
                          style={{
                            background: " rgba(0,0,0,0.3) ",
                            color: "white"
                          }}
                          placeholder="saisir votre code"
                          className={
                            "form-control" +
                            (errors.Code && touched.Code ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="Code"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="row">
                      {" "}
                      <div className="form-group" style={{ color: "white" }}>
                        <label htmlFor="Password">Mot de passe</label>
                        <CInputGroup className="mb-3">
                          <Field
                            name="Password"
                            type={isPasswordShown ? "text" : "password"}
                            style={{
                              background: " rgba(0,0,0,0.3) ",
                              color: "white"
                            }}
                            placeholder="saisir votre mot de passe"
                            className={
                              "form-control" +
                              (errors.Password && touched.Password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <CInputGroupText>
                            <CIcon
                              icon={cilLowVision}
                              onClick={this.togglePasswordVisiblity}
                            />
                          </CInputGroupText>
                          <ErrorMessage
                            name="Password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </CInputGroup>
                      </div>
                    </div>
                    <div className="row mt-4 d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn btn-primary submitbtn"
                      >
                        Se connecter
                      </button>
                    </div>

                    <div className="row mt-4 d-flex justify-content-center">
                      {isSubmitting}

                      {status && (
                        <div className={"alert alert-danger"}>{status}</div>
                      )}
                    </div>

                    <div className="row mt-4 d-flex justify-content-center">
                      {errorMessage && (
                        <div className={"alert alert-danger"}>
                          {errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default Login;
