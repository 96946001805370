import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilSpeedometer,
  cilBuilding,
  cilCash,
  cilTransfer,
  cilCog,
  cilBorderAll,
  cilStar,
  cilBadge,
  cilUser
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/",
    CNavLink: "/dashboard",

    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "info",
      text: `${
        JSON.parse(localStorage.getItem("currentUser")) != null
          ? JSON.parse(localStorage.getItem("currentUser")).No
          : ""
      }`
    }
  },

  {
    component: CNavItem,
    name: "Profil",
    to: "/profil",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />
  },
  {
    component: CNavTitle,
    name: "Etat financier"
  },
  {
    component: CNavItem,
    name: "Mes transactions",
    to: "/transactions",
    icon: <CIcon icon={cilTransfer} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: "Mes paiements",
    to: "/cheque",
    icon: <CIcon icon={cilCash} customClassName="nav-icon" />
  },
  {
    component: CNavTitle,
    name: "Etat produit"
  },
  {
    component: CNavItem,
    name: "Mes échantillons",
    to: "/echantillons",
    icon: <CIcon icon={cilBorderAll} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: "Mes réparations",
    to: "/repa",
    icon: <CIcon icon={cilCog} customClassName="nav-icon" />
  },

  {
    component: CNavTitle,
    name: "Demande formation"
  },
  {
    component: CNavItem,
    name: "Mes formations",
    to: "/formation",
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: "Original",
    to: "/original",
    icon: <CIcon icon={cilBadge} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: "A propos TUS",
    to: "/ici",
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />
  }

];

export default _nav;
