import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppHeader, AppFooter, AppSidebar } from "./../../components/index";
import SlideEvent from "./SlideEvent";
import {
  CCard,
  CCardBody,
  CCol,
  CProgress,
  CRow,
  CCardImage
} from "@coreui/react";

import part1 from "src/assets/logo/dahua.png";
import part2 from "src/assets/logo/zkt.png";
import part3 from "src/assets/logo/zavag.png";
import part4 from "src/assets/logo/sonoff.png";
import part5 from "src/assets/logo/ascables.png";
import part6 from "src/assets/logo/global.png";
import part7 from "src/assets/media/1.png";
import part8 from "src/assets/media/2.png";
import part9 from "src/assets/media/3.png";
import part10 from "src/assets/media/4.png";

const Geolocalisation = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };


  const progressGroupExample1 = [
    { jour: "Lundi", title: "(8h--->18h)", value2: 100 },
    { jour: "Mardi", title: "(8h--->18h)", value2: 100 },
    { jour: "Mercredi", title: "(8h--->18h)", value2: 100 },
    { jour: "Jeudi", title: "(8h--->18h)", value2: 100 },
    { jour: "Vendredi", title: "(8h--->18h)", value2: 100 },
    { jour: "Samedi", title: "(8h--->13h)", value2: 50 }
  ];

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 ">
        <AppHeader />
        <div className="container-fluid">
          <SlideEvent />
          <big className="d-flex justify-content-center mt-4">
            <p>Nos partenaires</p>
          </big>

          <br />
          <div className="container-fluid">
            <Slider {...settings}>
              <CCol sm="auto">
                <a
                  href="https://www.tus.com.tn/fournisseur/dahua/"
                  style={{ textDecoration: "none" }}
                >
                  <CCardImage
                    orientation="top"
                    src={part1}
                    className="partners"
                    alt="Logo1"
                  />
                </a>
              </CCol>
              <CCol sm="auto">
                <a
                  href="https://www.tus.com.tn/fournisseur/zkteco/"
                  style={{ textDecoration: "none" }}
                >
                  <CCardImage
                    className="partners"
                    orientation="top"
                    src={part2}
                    alt="Logo2"
                  />
                </a>
              </CCol>
              <CCol sm="auto">
                <a
                  href="https://www.tus.com.tn/fournisseur/zavag/"
                  style={{ textDecoration: "none" }}
                >
                  <CCardImage
                    className="partners"
                    orientation="top"
                    src={part3}
                    alt="Logo3"
                  />
                </a>
              </CCol>
              <CCol sm="auto">
                <a
                  href="https://www.tus.com.tn/categorie/incendie/"
                  style={{ textDecoration: "none" }}
                >
                  <CCardImage
                    className="partners"
                    orientation="top"
                    src={part4}
                    alt="Logo4"
                  />
                </a>
              </CCol>
              <CCol sm="auto">
                <a
                  href="https://www.tus.com.tn/categorie/incendie/"
                  style={{ textDecoration: "none" }}
                >
                  <CCardImage
                    className="partners"
                    orientation="top"
                    src={part5}
                    alt="Logo5"
                  />
                </a>
              </CCol>
              <CCol sm="auto">
                <a
                  href="https://www.tus.com.tn/categorie/incendie/"
                  style={{ textDecoration: "none" }}
                >
                  <CCardImage
                    className="partners"
                    orientation="top"
                    src={part6}
                    alt="Logo6"
                  />
                </a>
              </CCol>
            </Slider>
          </div>
          <br />
          <div className="container-fluid">
            <CRow>
              <CCol xs className="co">
                <CCard className="mb-4">
                  <CCardBody>
                    <CRow>
                      <CCol xs={12} md={6} xl={6}>
                        <hr className="mt-0" />
                        <h4>Notre disponibilité sur le terrain</h4>
                        {progressGroupExample1.map((item, index) => (
                          <div className="progress-group mb-2" key={index}>
                            <div className="progress-group-prepend">
                              <span className="text-medium-emphasis small">
                                {item.jour}
                              </span>
                            </div>
                            <div className="progress-group-bars">
                              {item.title}
                              <CProgress
                                thin
                                color="danger"
                                value={item.value2}
                              />
                              <CProgress
                                thin
                                color="info"
                                value={item.value1}
                              />
                            </div>
                          </div>
                        ))}
                      </CCol>

                     
                    </CRow>
                    <CRow className="mt-4">
                      <div className="tra">
                        <div className="fs-5 fw-semibold">
                          <big className="d-flex justify-content-center">
                            <p>Infoline</p>
                          </big>
                        </div>
                        <div className="bd-example center">
                          <div className="row">
                            <div className="col d-flex justify-content-center">
                              <a
                                href="https://www.facebook.com/tunisianunitedsolutions"
                                style={{ textDecoration: "none" }}
                              >
                                <img src={part7} alt="part7" />
                              </a>
                            </div>
                            <div className="col d-flex justify-content-center">
                              <a
                                href="https://www.linkedin.com/company/tunisian-united-solutions"
                                style={{ textDecoration: "none" }}
                              >
                                <img src={part8} alt="part8" />
                              </a>
                            </div>

                            <div className="col d-flex justify-content-center">
                              <a
                                href="https://www.instagram.com/tus.tunisie"
                                style={{ textDecoration: "none" }}
                              >
                                <img src={part9} alt="part9" />
                              </a>
                            </div>
                            <div className="col d-flex justify-content-center">
                              <a
                                href="https://www.youtube.com/channel/UC7WlYtD-7QwwbFhGUM88xjg"
                                style={{ textDecoration: "none" }}
                              >
                                <img src={part10} alt="part10" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CRow>
                    <br />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default Geolocalisation;
