import React from "react";
import { useEffect, useState } from "react";
import { AppHeader, AppFooter, AppSidebar } from "./../../components/index";
import ProductSlider from "./ProductSlider";

import { getStyle, hexToRgba } from "@coreui/utils";

import { CChartLine } from "@coreui/react-chartjs";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CWidgetStatsC,
  CButton,
  CButtonGroup
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { cilMoney, cilSpeedometer, cilChartPie } from "@coreui/icons";

import "./dash.css";

const Dashboard = () => {
  const [activeButton, setActiveButton] = useState(getLastThreeMonths()[2]); // Initial active button

  const handleButtonClick = value => {
    setActiveButton(value);
  };

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("Token")
  };

  const [filtercharts, setfilterchart] = useState([
    [
      {
        labels: ["a", "b", "c"],
        dataset1: [0, 0, 0],
        dataset2: [0, 0, 0],
        dataset3: [0, 0, 0]
      }
    ]
  ]);
  const [imageUrl, setimage] = useState(null);
  const [credit, setCredit] = useState(null);

  const fetchcredit = () => {
    fetch("https://vtronn.com/tusapi/public/api/credit", {
      method: "GET",
      headers: headers
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setCredit(data);
        var image = `https://emailing.tus-appli.com/assets/images/${data.data.Echeance}.png`;
        setimage(image);
      });
  };

  const fetchChart = () => {
    fetch("https://vtronn.com/tusapi/public/api/chart", { headers })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setfilterchart([data]);
      });
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  function getLastThreeMonths() {
    const today = new Date();
    const monthsArray = [];

    for (let i = 2; i >= 0; i--) {
      const currentMonth = new Date(
        today.getFullYear(),
        today.getMonth() - i,
        1
      );
      const lastmonth = new Date(
        today.getFullYear(),
        today.getMonth() - i + 1,
        1
      );
      monthsArray[
        currentMonth.toLocaleString("fr", { month: "long" }) +
          "-" +
          lastmonth.toLocaleString("fr", { month: "long" })
      ] = [formatDate(currentMonth), formatDate(lastmonth)];
    }

    return monthsArray;
  }
  useEffect(() => {
    fetchcredit();
    fetchChart();
  }, []);

  return (
    <>
      <div className="coll">
        <AppSidebar />
        <div className="wrapper d-flex flex-column  bg-light">
          <AppHeader />
          <div>
            <div>
              <div className="container-fluid">
                <CRow>
                  <CCol md={3} xs={6}>
                    <CWidgetStatsC
                      className="mb-3"
                      color="danger"
                      inverse
                      text=""
                      icon={<CIcon icon={cilMoney} height={36} />}
                      title="Solde"
                      value={`${
                        credit && credit.data
                          ? parseFloat(credit.data.Solde).toLocaleString(
                              "FR-fr"
                            )
                          : "..."
                      } TND`}
                    />
                  </CCol>
                  <CCol md={3} xs={6}>
                    <CWidgetStatsC
                      className="mb-3"
                      color="success"
                      inverse
                      text="Widget helper text"
                      icon={<CIcon icon={cilChartPie} height={36} />}
                      title="Encours"
                      value={`${
                        credit && credit.data
                          ? parseFloat(credit.data.Encours).toLocaleString(
                              "FR-fr"
                            )
                          : "..."
                      } TND`}
                    />
                  </CCol>
                  <CCol md={3} xs={6}>
                    <CWidgetStatsC
                      className="mb-3"
                      title="Echéance"
                      icon={
                        <img
                          src={imageUrl}
                          style={{
                            width: "36px",
                            height: "auto",
                            margin: "auto"
                          }}
                        />
                      }
                      value={`${
                        credit && credit.data
                          ? parseFloat(credit.data.Echeance).toLocaleString(
                              "FR-fr"
                            )
                          : "..."
                      } Jours`}
                    />
                  </CCol>
                  <CCol md={3} xs={6}>
                    <CWidgetStatsC
                      className="mb-3"
                      color="primary"
                      inverse
                      /*progress={{
                          color: getColor({
                            credit && credit.data
                              ? parseFloat(credit.data.Pourcentage).toLocaleString(
                                  "FR-fr"
                                )
                              : "N/A"
                          }),
                          value: credit.data.Pourcentage
                        }}*/
                      text=""
                      title="Pourcentage"
                      icon={<CIcon icon={cilSpeedometer} height={36} />}
                      value={`${
                        credit && credit.data
                          ? parseFloat(credit.data.Pourcentage).toLocaleString(
                              "FR-fr"
                            )
                          : "..."
                      } %`}
                    />
                  </CCol>
                </CRow>
              </div>
            </div>

            <div className="container-fluid">
              <>
                <CCard className="">
                  <CCardBody>
                    <CRow>
                      <CCol sm={5}>
                        <h4 id="traffic" className="card-title mb-0">
                          Traffic
                        </h4>
                        <div className="small text-medium-emphasis">
                          {activeButton} {new Date().getFullYear()}
                        </div>
                      </CCol>
                      <CCol sm={7} className="d-none d-md-block">
                        <CButtonGroup className="float-end me-3">
                          {Object.keys(getLastThreeMonths()).map(value => (
                            <CButton
                              color="outline-secondary"
                              key={value}
                              className="mx-0"
                              active={value === activeButton}
                              onClick={() => handleButtonClick(value)}
                            >
                              {value}
                            </CButton>
                          ))}
                        </CButtonGroup>
                      </CCol>
                    </CRow>
                    <CChartLine
                      style={{ height: "300px", marginTop: "40px" }}
                      data={{
                        labels: filtercharts[0].labels,
                        datasets: [
                          {
                            label: "Payement",
                            backgroundColor: hexToRgba(
                              getStyle("--cui-info"),
                              10
                            ),
                            borderColor: getStyle("--cui-info"),
                            pointHoverBackgroundColor: getStyle("--cui-info"),
                            borderWidth: 2,
                            data: filtercharts[0].dataset1,
                            fill: true
                          },
                          {
                            label: "Factures",
                            backgroundColor: "transparent",
                            borderColor: getStyle("--cui-success"),
                            pointHoverBackgroundColor: getStyle(
                              "--cui-success"
                            ),
                            borderWidth: 2,
                            data: filtercharts[0].dataset2
                          },
                          {
                            label: "Avoir",
                            backgroundColor: "transparent",
                            borderColor: getStyle("--cui-danger"),
                            pointHoverBackgroundColor: getStyle("--cui-danger"),
                            borderWidth: 1,
                            borderDash: [8, 5],
                            data: filtercharts[0].dataset3
                          }
                        ]
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false
                          }
                        },
                        scales: {
                          x: {
                            grid: {
                              drawOnChartArea: false
                            }
                          },
                          y: {
                            ticks: {
                              beginAtZero: true,
                              maxTicksLimit: 5,
                              stepSize: Math.ceil(250 / 5),
                              max: 250
                            }
                          }
                        },
                        elements: {
                          line: {
                            tension: 0.4
                          },
                          point: {
                            radius: 0,
                            hitRadius: 10,
                            hoverRadius: 4,
                            hoverBorderWidth: 3
                          }
                        }
                      }}
                    />
                  </CCardBody>
                </CCard>
              </>

              <br />
              <br />
              <div className="container-fluid">
                {" "}
                <ProductSlider />
              </div>
              <br />
            </div>

            <br />
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
};

export { Dashboard };
