import React, { useEffect, useState } from "react";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react";
import { authenticationService } from "./../../_services/index";
import { history } from "./../../_helpers";

import {
  cilLockLocked,
  cilUser,
  cilLocationPin,
  cilPlus,
  cilBell
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import avatar9 from "./../../assets/images/client.jpg";
import "./../../scss/style.scss";

const AppHeaderDropdown = () => {
  const [total, setTotal] = useState([]);

  useEffect(() => {
    fetchEm();
  }, []);
  const fetchEm = async () => {
    const fetchEm = await fetch(
      `https://vtronn.com/tusapi/public/api/cheques`,
      {
        crossDomain: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem(`Token`),
          "Content-Type": "application/json"
        },
        method: "POST"
      }
    );
    var data = await fetchEm.json();

    setTotal(data.Total);
    console.log(data.Total);
  };
  const logout = () => {
    authenticationService.logout();
    history.push("/login");
  };

  {
    return (
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <CAvatar src={avatar9} size="md" />
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownHeader className="bg-light fw-semibold py-2">
            Compte
          </CDropdownHeader>
          <CDropdownItem href="/profil">
            <CIcon icon={cilUser} className="me-2" />
            Profil
          </CDropdownItem>
          <CDropdownItem href="/info">
            <CIcon icon={cilPlus} className="me-2" />
            Mise à jour de compte
          </CDropdownItem>

          <CDropdownHeader className="bg-light fw-semibold py-2">
            Informations
          </CDropdownHeader>
          <CDropdownItem href="/ici">
            <CIcon icon={cilLocationPin} className="me-2" />
            TUS
          </CDropdownItem>
          <CDropdownHeader className="bg-light fw-semibold py-2">
            Activités
          </CDropdownHeader>
          <CDropdownItem href="/cheque">
            <CIcon icon={cilBell} className="me-2" />
            Les paiements
            <CBadge color="danger" className="ms-2">
              {total.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}{" "}
              DT
            </CBadge>
          </CDropdownItem>
          <CDropdownDivider />

          <CDropdownItem href="/login" onClick={logout}>
            <CIcon icon={cilLockLocked} className="me-2" />
            Se déconnecter
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    );
  }
};

export default AppHeaderDropdown;
