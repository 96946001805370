import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from './../../_services';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const isAuthenticated = localStorage.getItem("isAuthenticated");
        console.log("this", isAuthenticated);
        const currentUser = authenticationService.currentUserValue;
        isAuthenticated ? <Component {...props} /> : <Redirect to="/signin" />
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // authorised so return component
      
        return <Component {...props} />
        
    }} />
)