import React, { useEffect, useState } from "react";
import { AppHeader, AppFooter, AppSidebar } from "../../components/index";
import "./profile.css";
import user from "src/assets/images/user.png";

const Profile = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchEm();
  }, []);
  const fetchEm = async () => {
    const fetchEm = await fetch(
      `https://vtronn.com/tusapi/public/api/customer_info`,
      {
        crossDomain: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem(`Token`),
          "Content-Type": "application/json"
        }
      }
    );
    var data = await fetchEm.json();
    setUsers(data.data);
    console.log(data.data);
  };

  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100">
          <AppHeader />
          <div className="container-fluid">
            <div className="row m-2">
              <div className="col-sm-4 bg-c-lite-green ">
                <div className="card-block text-center text-white">
                  <div className="m-b-25">
                    <img
                      src={user}
                      className="img-radius"
                      alt="profile"
                    />
                  </div>

                  <p>{users.Name}</p>
                  <i className="mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>

                  <ul className="social-link list-unstyled m-t-40 m-b-10 Mise">
                    <li></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card-block">
                  <h6 className="mt-4  f-w-600">Mes coordonées personnelles</h6>
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Code</p>
                      <h6 className="text-muted f-w-400" id="no">
                        {users.No}
                      </h6>
                    </div>
                    <div className="col-sm-6 ">
                      <p className="m-b-10 f-w-600">Matricule fiscale</p>
                      <h6 className="text-muted f-w-400">
                        {users.VAT_Registration_No}
                      </h6>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">E_Mail</p>
                      <h6 className="text-muted f-w-400">{users.E_Mail}</h6>
                    </div>
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Telephone</p>
                      <h6 className="text-muted f-w-400">{users.Phone_No}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Contact</p>
                      <h6 className="text-muted f-w-400">{users.Contact}</h6>
                    </div>
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Adresse</p>
                      <h6 className="text-muted f-w-400">
                        {users.Country_Region_Code}
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Commercial</p>
                      <h6 className="text-muted f-w-400">
                        {users.Responsibility_Center}
                      </h6>
                    </div>
                    <div className="col-sm-6">
                      <p className="m-b-10 f-w-600">Code Postal</p>
                      <h6 className="text-muted f-w-400">{users.Post_Code}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppFooter />
    </>
  );
};

export default Profile;
