import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { AppHeader, AppFooter, AppSidebar } from "../../../components/index";
import { ModalHeader, Modal, ModalBody } from "reactstrap";

import styled from "styled-components";

const Transactions = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedVoir, setSelectedVoir] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      const response = await fetch(
        `https://vtronn.com/tusapi/public/api/transactions`,
        {
          crossDomain: true,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Token")
          },
          method: "POST"
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch transactions");
      }

      const data = await response.json();
      setTableData(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDetailClick = async facture => {
    try {
      const response = await fetch(
        `https://vtronn.com/tusapi/public/api/transaction/view?facture=${facture}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Token"),
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch transaction details");
      }

      const voirData = await response.json();
      console.log(voirData);

      setSelectedVoir(voirData);
      setIsModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setSelectedVoir(null);
    setIsModalOpen(false);
  };

  const columns = [
    { field: "Document_No", headerName: "Ref de document", width: 250 },
    { field: "type", headerName: "Type de document", width: 250 },
    { field: "Posting_Date", headerName: "Date", width: 250 },
    { field: "Amount", headerName: "Montant en DT", width: 200 },
    {
      field: "Action",
      headerName: "Action",
      width: 300,
      renderCell: data => (
        <div>
          <button
            onClick={() => handleDetailClick(data.row.Document_No)}
            className="btn btn-primary m-1"
          >
            {" "}
            voir
          </button>
        </div>
      )
    }
  ];

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader />
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "Posting_Date", sort: "desc" }]
            }
          }}
          style={{ margin: "20px" }}
          rows={tableData}
          columns={columns}
          pageSize={20}
          components={{ Toolbar: GridToolbar }}
        />

        <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
          <ModalHeader style={{ margin: "auto" }}>Details</ModalHeader>
          <ModalBody>
            {selectedVoir && (
              <Card>
                <CardItem>
                  <Label>Montant en dt :</Label>
                  <Value>{selectedVoir.Amount}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Nom du client :</Label>
                  <Value>{selectedVoir.Customer_Name}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Numéro de client :</Label>
                  <Value>{selectedVoir.Customer_No}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Description :</Label>
                  <Value>{selectedVoir.Description}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Numéro de document:</Label>
                  <Value>{selectedVoir.Document_No}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Type de document :</Label>
                  <Value>{selectedVoir.Document_Type}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Date d'échéance :</Label>
                  <Value>{selectedVoir.Due_Date}</Value>
                </CardItem>
                <br />
                <br />
                <CardItem>
                  <Label>Open:</Label>
                  <Value>{selectedVoir.Open}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Code du mode de paiement:</Label>
                  <Value>{selectedVoir.Payment_Method_Code}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Numéro de titre de paiement:</Label>
                  <Value>{selectedVoir.Payment_Title_No}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Date de saisie :</Label>
                  <Value>{selectedVoir.Posting_Date}</Value>
                </CardItem>
                <br />
                <CardItem>
                  <Label>Centre de responsabilité :</Label>
                  <Value>{selectedVoir.Responsibility_Center}</Value>
                </CardItem>
              </Card>
            )}
          </ModalBody>
        </Modal>
      </div>
      <AppFooter />
    </div>
  );
};

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Value = styled.span`
  margin-bottom: 10px;
`;

export default Transactions;
