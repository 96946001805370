import React, { useEffect, useState } from "react";

import { AppHeader, AppFooter, AppSidebar } from "../../../components/index";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  { field: "Nom_Echan", headerName: "Nom d'échantillon", width: 260 },
  { field: "Quantity", headerName: "Quantité", width: 120, align: "center" },
  { field: "Serial_No", headerName: "Nom de série", width: 270 },
  { field: "Date_Reçu", headerName: "Date de Reçu", width: 200 },
  { field: "situation", headerName: "Situation", width: 230 }
];

const Echantillons = () => {
  

  const [echan, setEchan] = useState([]);
  
  useEffect(() => {
    fetchEm();
  }, []);
  const fetchEm = async () => {
    const fetchEm = await fetch(
      `https://vtronn.com/tusapi/public/api/echantillon`,
      {
        crossDomain: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem(`Token`)
          //"Content-Type": "application/json",
        },
        method: "POST"
      }
    );
    const records = await fetchEm.json();
    setEchan([records.data]);
    console.log(records.data);
  };
  function addDays(expirationDate) {
    if (expirationDate === "0001-01-01") {
      return { message: "Date est valable", color: "#00ff00" };
    } else {
      var todate = Date.parse(expirationDate);
      var ms = 86400000;
      var today = new Date();
      var dateech = todate + ms;
      if (today > dateech) {
        return { message: "Echantillon à ramener", color: "#ff0000" };
      } else {
        return { message: "Date est valable", color: "#00ff00" };
      }
    }
  }
  const renderSituationCell = params => {
    const { value: expirationDate } = params;
    const result = addDays(expirationDate);

    return (
      <div style={{ color: result.color }}>
        {result.message}{" "}
        {expirationDate !== "0001-01-01" && `(${expirationDate})`}
      </div>
    );
  };
  const generateUniqueId = () => {
    return Math.random()
      .toString(36)
      .substr(2, 9); // Génère une chaîne aléatoire comme identifiant
  };
  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100">
          <AppHeader />

          {echan.length === 0 ? (
            <div></div>
          ) : (
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: "Date_Reçu", sort: "desc" }]
                }
              }}
              style={{ margin: "20px" }}
              rows={echan.map(
                ({
                  Item_No,
                  Expiration_Date,
                  Quantity,
                  Posting_Date,
                  Serial_No
                }) => ({
                  id: generateUniqueId(), // Utilisation de l'identifiant généré côté client
                  Nom_Echan: Item_No,
                  Quantity: Quantity,
                  Serial_No: Serial_No,
                  Date_Reçu: Posting_Date,
                  situation: Expiration_Date
                })
              )}
              columns={[
                ...columns,
                {
                  field: "situation",
                  headerName: "Situation",
                  width: 250,
                  renderCell: renderSituationCell
                }
              ]}
              pageSize={5}
              components={{
                Toolbar: GridToolbar
              }}
            />
          )}

          <AppFooter />
        </div>
        <AppFooter />
      </div>
    </>
  );
};

export default Echantillons;
