import React from "react";
import { CFooter } from "@coreui/react";

const AppFooter = () => {
  return (
    <CFooter>
      <div></div>
      <div className="ms-auto">
        <span className="me-1">Copyright</span>
        <a
          href="https://www.tus.com.tn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          TUS &copy; {new Date().getFullYear()}
        </a>
      </div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
