import React, { useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CInputGroup,
  CFormInput,
  CButton,
  CCardTitle,
  CAlert,
  CCardFooter,
  CSpinner
} from "@coreui/react";
import { QrReader } from "react-qr-reader";
import { ModalHeader, Modal, ModalBody } from "reactstrap";
import { AppHeader, AppFooter, AppSidebar } from "../../../components/index";
import CIcon from "@coreui/icons-react";
import { cilQrCode, cilSearch, cilCheckCircle, cilFrown } from "@coreui/icons";

import { CFormSelect } from "@coreui/react";

const Original = () => {
  const toggleModal = () => setStartScan(!startScan);
  const [SerialNumber, setSerialNumber] = useState("");
  const [alerttext, setAlerttext] = useState("Chercher votre Numéro de serie");
  const [selectedcam, setSelected] = useState("environment");
  const [RSstatus, setRSstatus] = useState("");
  const [RSresult, setRSresult] = useState([]);
  const [startScan, setStartScan] = useState(false);

  const [loading, setLoading] = useState(false);

  const getexist = async SN => {
    if (SN != null && SN != "") {
      setSerialNumber(SN);
      setLoading(true);
      try {
        const response = await fetch(
          `https://vtronn.com/tusapi/public/api/snexist`,
          {
            method: "POST",
            body: JSON.stringify({
              serial: SN
            }),
            headers: {
              Authorization: "Bearer " + localStorage.getItem("Token"),
              "Content-Type": "application/json"
            }
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch transaction details");
        }

        const voirData = await response.json();
        setRSresult(voirData.data);
        setRSstatus(voirData.status);
        setLoading(false);
        console.log(voirData);
      } catch (error) {
        console.error(error);
      }
    } else {
      setAlerttext("vous devez remplir le champ du numéro de série !");
    }
  };
  const handleDetailClick = async () => {
    getexist(SerialNumber);
  };

  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100">
          <AppHeader />

          <div className="container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-md-4 p-4">
                <CInputGroup className="">
                  <CFormInput
                    placeholder="Please input SN"
                    id="serialn"
                    onChange={e => setSerialNumber(e.target.value)}
                    value={SerialNumber}
                    aria-describedby="button-addon2"
                  />
                  <CButton
                    type="button"
                    color="secondary"
                    variant="outline"
                    id="button-addon2"
                    onClick={handleDetailClick}
                  >
                    <CIcon icon={cilSearch} height={30} />
                  </CButton>
                  <CButton
                    type="button"
                    color="secondary"
                    variant="outline"
                    id="button-addon2"
                    onClick={() => {
                      setStartScan(!startScan);
                    }}
                  >
                    <CIcon icon={cilQrCode} height={30} />
                  </CButton>
                </CInputGroup>
              </div>
            </div>

            <div className="row d-flex justify-content-center mt-4">
              <CCard className="text-center">
                <CCardHeader>Resultat</CCardHeader>

                <CCardBody>
                  {loading ? (
                    <>
                      <div className="d-flex justify-content-center">
                        <CSpinner />
                      </div>
                    </>
                  ) : (
                    <>
                      <CCardTitle>
                        {RSstatus == "" ? (
                          <>
                            <CAlert color="secondary">{alerttext}</CAlert>
                          </>
                        ) : (
                          <>
                            {RSstatus == "success" ? (
                              <>
                                <CAlert
                                  color="success"
                                  className="d-flex align-items-center"
                                >
                                  <CIcon
                                    icon={cilCheckCircle}
                                    className="flex-shrink-0 me-2"
                                    width={24}
                                    height={24}
                                  />
                                  <div>Ce produit est original TUS</div>
                                </CAlert>
                              </>
                            ) : (
                              <>
                                <CAlert
                                  color="danger"
                                  className="d-flex align-items-center"
                                >
                                  <CIcon
                                    icon={cilFrown}
                                    className="flex-shrink-0 me-2"
                                    width={24}
                                    height={24}
                                  />
                                  <div>Ce produit n'est pas original TUS</div>
                                </CAlert>
                              </>
                            )}
                          </>
                        )}
                      </CCardTitle>
                      <div className="row table-responsive">
                        {RSstatus == "success" && (
                          <>
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">SN</th>
                                  <th scope="col">Model</th>
                                  <th scope="col">Désignation</th>
                                  <th scope="col">Date Inscription</th>
                                  <th scope="col">Client</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">{RSresult.a}</th>
                                  <td>{RSresult.b}</td>
                                  <td>{RSresult.c}</td>
                                  <td>{RSresult.d}</td>
                                  <td>{RSresult.e}</td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </CCardBody>
                <CCardFooter className="text-medium-emphasis"></CCardFooter>
              </CCard>
            </div>
          </div>
        </div>

        <Modal isOpen={startScan} toggle={toggleModal}>
          <ModalHeader style={{ margin: "auto" }}>Scan qrcode</ModalHeader>
          <ModalBody>
            <CFormSelect onChange={e => setSelected(e.target.value)}>
              <option value={"environment"}>Back Camera</option>
              <option value={"user"}>Front Camera</option>
            </CFormSelect>
            <QrReader
              constraints={{ facingMode: selectedcam }}
              delay={1000}
              onResult={(result, error) => {
                if (!!result) {
                  getexist(result.text);

                  setStartScan(false);
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              style={{ width: "100%" }}
            />
          </ModalBody>
        </Modal>
        <AppFooter />
      </div>
    </>
  );
};
export default Original;
