import React from "react";

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));

const Geolocalisation = React.lazy(() => import("./views/map/Geolocalisation"));
const Reparation = React.lazy(() => import("./views/reparation/Reparation"));

const Profile = React.lazy(() => import("./views/profile/Profile"));

const Transactions = React.lazy(() =>
  import("./views/actions/transaction/Transactions")
);
const Cheque = React.lazy(() => import("./views/actions/cheque/Cheque"));
const Original = React.lazy(() => import("./views/actions/original/Original"));

const Echantillons = React.lazy(() =>
  import("./views/produits/echantillon/Echantillons")
);

const routes = [
  { path: "/", exact: true, name: " Acceuil" },

  { path: "/dashboard", name: "Dashboard", component: Dashboard },

  { path: "/ici", name: "Info", component: Geolocalisation },
  { path: "/repa", name: "Reparations", component: Reparation },

  { path: "/original", name: "Original", component: Original },
  { path: "/profil", name: "Profile", component: Profile },
  

  { path: "/icons", exact: true, name: "Icons", component: Transactions },
  { path: "/transactions", name: "Transactions", component: Transactions },
  { path: "/cheque", name: "Cheque", component: Cheque },
  {
    path: "/notifications",
    name: "Notifications",
    component: Echantillons,
    exact: true
  },
  { path: "/echantillons", name: "Echantillons", component: Echantillons }
];

export default routes;
