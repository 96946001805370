import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";

import { PrivateRoute } from "./components/private/PrivateRoute";

import { authenticationService } from "./_services";

import Login from "./views/pages/login/Login";
import { Dashboard } from "./views/dashboard/Dashboard";
import { history } from "./_helpers/history";
import Profile from "./views/profile/Profile";

import Echantillons from "./views/produits/echantillon/Echantillons";
import Transactions from "./views/actions/transaction/Transactions";
import Cheque from "./views/actions/cheque/Cheque";

import Geolocalisation from "./views/map/Geolocalisation";
import Reparation from "./views/reparation/Reparation";

import Page404 from "./views/pages/page404/Page404";

import Formation from "./views/actions/formation/Formation";


import Original from "./views/actions/original/Original";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(x =>
      this.setState({ currentUser: x })
    );
  }
  logout() {
    authenticationService.logout();
    history.push("/login");
  }

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route path="/login" component={Login} />

            <PrivateRoute exact path="/" component={Dashboard} />

            <PrivateRoute exact path="/original" component={Original} />

            <PrivateRoute exact path="/profil" component={Profile} />
            <PrivateRoute exact path="/echantillons" component={Echantillons} />
            <PrivateRoute exact path="/transactions" component={Transactions} />
            <PrivateRoute exact path="/formation" component={Formation} />
           

            
            <PrivateRoute exact path="/cheque" component={Cheque} />

            <PrivateRoute exact path="/ici" component={Geolocalisation} />
            <PrivateRoute exact path="/repa" component={Reparation} />

            <Route path="*" component={Page404} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
