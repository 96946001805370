
import { useEffect, useState } from "react";
import{CCard,CCardImage,CCardBody,CCardTitle,CCardText,CButton,CPlaceholder} from "@coreui/react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductSlider = () => {
  const [produits, setProduits] = useState([]);
  const [loading,setloading]=useState(false);
  const headers = {
    Autorization: "Bearer" + localStorage.getItem("Token"),
    method: "GET"
  };
  const test=[1,2,3,4];
  const fetchData2 = () => {
    setloading(true);
    fetch("https://vtronn.com/tusapi/public/api/produits", { headers })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setProduits(data);
        setloading(false);
        console.log(data);
      });
  };
  useEffect(() => {
    fetchData2();
  }, []);
  var settingstwo = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true, // Add autoplay property to enable automatic sliding
    autoplaySpeed: 3000, // Set the interval (in milliseconds) between slide transitions
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <div>
      <div className="fs-5 fw-semibold">
        <big className="d-flex justify-content-center">
          {" "}
          <h6 class="text">Liste des Produits</h6>
        </big>
      </div>
      <br />
      {loading?(
        <>
         <Slider {...settingstwo}>
         {test.map((prod, index) => {
          return (
            <>
               <CCard >
  <CCardImage component="svg" orientation="top" width="100%" height="162" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
    <title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect>
  </CCardImage>
  <CCardBody>
    <CPlaceholder component={CCardTitle} animation="glow" xs={7}>
      <CPlaceholder xs={6} />
    </CPlaceholder>
    <CPlaceholder component={CCardText} animation="glow">
      <CPlaceholder xs={7} />
      <CPlaceholder xs={4} />
      <CPlaceholder xs={4} />
      <CPlaceholder xs={6} />
      <CPlaceholder xs={8} />
    </CPlaceholder>
    <CPlaceholder component={CButton} disabled href="#" tabIndex={-1} xs={6}></CPlaceholder>
  </CCardBody>
</CCard>
            </>
            
          );
        })}
         </Slider>
        </>
      ):(<>
      <Slider {...settingstwo}>
        {produits.map((prod, index) => {
          return (
            <>
               <CCard key={index} >
              <CCardImage orientation="top" src={prod.image} />
              <CCardBody>
               <CCardTitle></CCardTitle>
            <CCardText>
            {prod.title}
        </CCardText>
      <CButton href={prod.link}>Voir</CButton>
    </CCardBody>
      </CCard>
              {/* <img src={slide.img} alt={`slide${index}`} /> */}
            </>
            
          );
        })}
      </Slider>
      </>)}
      
    </div>
  );
};

export default ProductSlider;
