import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {CCardImage} from "@coreui/react";
import { useEffect, useState } from "react";
import blanc from "src/assets/images/blanc.jpg";
import "./sty.css";

const SlideEvent = () => {
  const [events, setEvents] = useState([]);
  const [loading,setloading]=useState(false);
  const headers = {
    Autorization: "Bearer" + localStorage.getItem("Token"),
    method: "GET"
  };

  const fetchData = () => {
    setloading(true);
    fetch("https://vtronn.com/tusapi/public/api/events", { headers })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setEvents(data);
        setloading(false)
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="mt-2">
          <div className="d-flex justify-content-center ">
           
            <h6 >Evènements TUS</h6>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            {loading?(
              <>
              <CCardImage component="svg" orientation="top" width="100%" height="200" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#868e96"></rect></CCardImage>
              </>
            ):(
              <>
               <Carousel>
              {events.map(({ id, post_date, title, link, image, desc }) => (
                <Carousel.Item key={id}>
                  <a
                    href={link}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <img
                      className="d-block w-100 "
                      src={image}
                      alt={`Slide ${id}`}
                    />
                   
                    
                  </a>
                </Carousel.Item>
              ))}
            </Carousel>
              </>
            )}
           
          </div>
        </div>
      </div>
    </div>
  );
};
export default SlideEvent;
