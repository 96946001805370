import React, { useEffect, useState } from "react";

import { AppHeader, AppFooter, AppSidebar } from "../../../components/index";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  { field: "Document_No", headerName: "Numero", width: 400 },
  { field: "Original_Amt_LCY", headerName: "Montant en dt", width: 250 },
  { field: "Payment_Method_Code", headerName: "Payment", width: 150 },
  {
    field: "Due_Date",
    headerName: "Date d échéance",
    width: 200,
    renderCell: data => {
      const dueDate = new Date(data.row.Due_Date);
      const today = new Date();

      let textColor = "black";
      if (dueDate < today) {
        textColor = "red";
      }

      return <div style={{ color: textColor }}>{data.row.Due_Date}</div>;
    }
  }
];

const Cheque = () => {
  const [tableCheque, setTableCheque] = useState([]);

  useEffect(() => {
    fetchEm();
  }, []);
  const fetchEm = async () => {
    const fetchEm = await fetch(
      `https://vtronn.com/tusapi/public/api/cheques`,
      {
        crossDomain: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem(`Token`)
          //"Content-Type": "application/json",
        },
        method: "POST"
      }
    );
    var data = await fetchEm.json();
    setTableCheque(data.data);
    console.log(data.data);

    console.log(data.Total);
  };

  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100">
          <AppHeader />

          <DataGrid
            style={{ margin: "20px" }}
            rows={tableCheque.map((item, index) => ({
              id: index,
              Document_No: item.Document_No,
              Original_Amt_LCY: item.Original_Amt_LCY,
              Due_Date: item.Due_Date,
              Payment_Method_Code: item.Payment_Method_Code
            }))}
            columns={columns}
            pageSize={20}
            components={{
              Toolbar: GridToolbar
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "Due_Date", sort: "desc" }]
              }
            }}
          />
        </div>
        <AppFooter />
      </div>
    </>
  );
};
export default Cheque;
