import React, { useEffect, useState } from "react";
import { AppHeader, AppFooter, AppSidebar } from "./../../components/index";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  { field: "description", headerName: "Nom de produit", width: 400 },
  { field: "Serial_No", headerName: "Référence", width: 250 },
  { field: "date", headerName: "Date de depot", width: 150 },
  {
    field: "statut",
    headerName: "Statut",
    width: 200,
    renderCell: params => <StatusCell {...params} />
  },
  { field: "reparateur", headerName: "Reparateur", width: 200 }
];

function valider(statut) {
  if (statut === "Pending") {
    return { message: "Produit en attente de réparation", color: "#ff0000" };
  } else {
    return {
      message: "Produit est déjà réparé",
      color: "#00ff00",
      animation: "fadeIn 0.8s ease-in-out"
    };
  }
}

const StatusCell = ({ value }) => {
  const { message, color } = valider(value);

  return <div style={{ color }}>{message}</div>;
};

const Reparation = () => {
 
  const [tableRepa, setTableRepa] = useState([]);

  useEffect(() => {
    fetchEm();
  }, []);

  const fetchEm = async () => {
    const response = await fetch(
      `https://vtronn.com/tusapi/public/api/reparation`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("Token")
        },
        method: "POST"
      }
    );
    const data = await response.json();
    setTableRepa(data.data);
    console.log(data.data);
  };

  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100">
          <AppHeader />
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }]
              }
            }}
            style={{ margin: "20px" }}
            rows={tableRepa.map((item, index) => ({
              id: index,
              description: item.description,
              Serial_No: item.Serial_No,
              date: item.date,
              statut: item.statut,
              reparateur: item.reparateur
            }))}
            columns={columns}
            pageSize={20}
            components={{
              Toolbar: GridToolbar
            }}
          />
        </div>
        <AppFooter />
      </div>
    </>
  );
};

export default Reparation;
